import {
  LOGIN,
  LOGINSSO,
  DEFAULT_ROUTE,
  isAuthenticatedRoute,
  isAnonymousRoute,
  LOGOUT,
} from 'src/router/routes'
import { isLoggedInSelector } from 'src/store/auth/selectors'
import { isHostSsoDomain } from 'src/utils/ssoHelper'

export const isUserAuthenticated = store => () => (
  toState,
  fromState,
  done
) => {
  const state = store.getState()
  const isLoggedIn = isLoggedInSelector(state)
  if (toState.name === LOGOUT) {
    // noop
  } else if (!isLoggedIn && isAuthenticatedRoute(toState.name)) {
    // Anonymous user trying to access authenticated route
    // eslint-disable-next-line prefer-promise-reject-errors
    if (isHostSsoDomain()) {
      return Promise.reject({ redirect: { name: LOGINSSO } })
    } else {
      return Promise.reject({ redirect: { name: LOGIN } })
    }
  } else if (isLoggedIn && isAnonymousRoute(toState.name)) {
    // Authenticated user trying to access anonymous route
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ redirect: { name: DEFAULT_ROUTE } })
  }
  done()
}
