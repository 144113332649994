import { actions as routerActions } from 'redux-router5'
import idx from 'idx'

import { hash } from 'src/utils/crypto'
import {
  createRequestAction,
  createSuccessAction,
  createErrorAction,
  createInProgressAction,
  createBasicActionTypes,
} from 'src/utils/createAction'

import {
  getSinglePayloadFromResponse,
  getPayloadFromErrorResponse,
  setAuthTokenHeader,
} from 'src/utils/api/core'

import {
  setRefreshTokenToStorage,
  setTokenToStorage,
  clearLocalStorage,
  setUserToStorage,
  setImageTokenToStorage,
  setRolesToStorage,
} from 'src/utils/storage'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import {
  loginUser,
  loginUserSso,
  logoutUser,
  refreshSession,
  requestNewPassword as requestNewPasswordApi,
  resetPassword,
  signupUser,
  updatePassword,
} from 'src/utils/api/auth'
import { LOGIN, LOGINSSO } from 'src/router/routes'
import { portsRequest } from 'src/store/ports/actions'
import { isHostSsoDomain } from 'src/utils/ssoHelper'

// local helper constants
const ACTION_BASE = 'AUTH'
const ACTION_LOGIN = `${ACTION_BASE}_LOGIN`
const ACTION_SESSION_REFRESH = `${ACTION_BASE}_REFRESH_SESSION`
const ACTION_LOGOUT = `${ACTION_BASE}_LOGOUT`
const ACTION_PASSWORD_REQUEST_NEW = `${ACTION_BASE}_PASSWORD_REQUEST_NEW`
const ACTION_PASSWORD_RESET = `${ACTION_BASE}_PASSWORD_RESET`
const ACTION_PASSWORD_UPDATE = `${ACTION_BASE}_PASSWORD_UPDATE`
const ACTION_SIGNUP = `${ACTION_BASE}_SIGNUP`

// exported action types
export const AUTH_ERROR_STATE_RESET = 'AUTH_ERROR_STATE_RESET'

export const [
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_CANCEL,
  AUTH_LOGIN_IN_PROGRESS,
] = createBasicActionTypes(ACTION_LOGIN)

export const [
  AUTH_SESSION_REFRESH_REQUEST,
  AUTH_SESSION_REFRESH_SUCCESS,
  AUTH_SESSION_REFRESH_ERROR,
  AUTH_SESSION_REFRESH_CANCEL,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SESSION_REFRESH)

export const [
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_CANCEL,
  AUTH_LOGOUT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_LOGOUT)

export const [
  AUTH_PASSWORD_REQUEST_NEW_REQUEST,
  AUTH_PASSWORD_REQUEST_NEW_SUCCESS,
  AUTH_PASSWORD_REQUEST_NEW_ERROR,
  AUTH_PASSWORD_REQUEST_NEW_CANCEL,
  AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_REQUEST_NEW)

export const [
  AUTH_PASSWORD_RESET_REQUEST,
  AUTH_PASSWORD_RESET_SUCCESS,
  AUTH_PASSWORD_RESET_ERROR,
  AUTH_PASSWORD_RESET_CANCEL,
  AUTH_PASSWORD_RESET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_RESET)

export const [
  AUTH_PASSWORD_UPDATE_REQUEST,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_PASSWORD_UPDATE_ERROR,
  AUTH_PASSWORD_UPDATE_CANCEL,
  AUTH_PASSWORD_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_UPDATE)

export const [
  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_ERROR,
  AUTH_SIGNUP_CANCEL,
  AUTH_SIGNUP_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SIGNUP)

export const SESSION_EXPIRED = 'SESSION_EXPIRED'

// Login
export const requestLogin = (email, password) => async dispatch => {
  dispatch(loginInProgress())
  try {
    const hashedPassword = hash(password)
    const response = await loginUser(email, hashedPassword, false)
    const responsePayload = getSinglePayloadFromResponse(response)
    dispatch(loggedIn(responsePayload))
    return responsePayload
  } catch (errorResponse) {
    let message
    const status = idx(errorResponse, e => e.response.status)
    if (
      errorResponse &&
      errorResponse.response &&
      [400, 401, 403].indexOf(status) !== -1
    ) {
      message = TOAST_MESSAGES.INVALID_LOGIN
    } else {
      message = TOAST_MESSAGES.GENERIC_ERROR
    }

    dispatch(loginError(getPayloadFromErrorResponse(errorResponse), message))
    return false
  }
}
export const requestLoginSso = (
  token,
  redirectUrl
) => async dispatch => {
  dispatch(loginInProgress())
  try {
    const response = await loginUserSso(token, redirectUrl)
    const responsePayload = getSinglePayloadFromResponse(response)
    dispatch(loggedIn(responsePayload))
    return responsePayload
  } catch (errorResponse) {
    let message
    const status = idx(errorResponse, e => e.response.status)
    if (
      errorResponse &&
      errorResponse.response &&
      [400, 401, 403].indexOf(status) !== -1
    ) {
      message = TOAST_MESSAGES.INVALID_LOGIN
    } else {
      message = TOAST_MESSAGES.GENERIC_ERROR
    }

    dispatch(loginError(getPayloadFromErrorResponse(errorResponse), message))
    return false
  }
}

export const loginInProgress = payload =>
  createInProgressAction(ACTION_LOGIN, payload)

export const loggedIn = result => dispatch => {
  setRefreshTokenToStorage(result.refreshToken)
  setTokenToStorage(result.token)
  setImageTokenToStorage(result.imageToken)
  setAuthTokenHeader(result.token)
  setUserToStorage(result.user)
  setRolesToStorage(result.roles)
  dispatch(createSuccessAction(ACTION_LOGIN, result))
  dispatch(portsRequest())
}

export const loginError = (error, message) => dispatch => {
  dispatch(createErrorAction(ACTION_LOGIN, error))
  dispatch(addErrorToast({ message }))
}

// Sign up
export const requestSignup = (token, password) => async dispatch => {
  dispatch(signupInProgress())
  try {
    const hashedPassword = hash(password)
    const response = await signupUser(token, hashedPassword)
    const responsePayload = getSinglePayloadFromResponse(response)

    dispatch(signupSuccess(responsePayload))
    dispatch(
      addSuccessToast({
        message: TOAST_MESSAGES.SIGNUP_SUCCESS,
      })
    )
    return true
  } catch (errorResponse) {
    dispatch(signupError(errorResponse))
    return false
  }
}

export const signupInProgress = payload =>
  createInProgressAction(ACTION_SIGNUP, payload)

export const signupSuccess = result => dispatch => {
  dispatch(createSuccessAction(ACTION_SIGNUP, result))
}

export const signupError = errorResponse => dispatch => {
  const error = getPayloadFromErrorResponse(errorResponse)
  const status = idx(errorResponse, e => e.response.status)
  dispatch(createErrorAction(ACTION_SIGNUP, error))
  // TODO: check error status (e.g. invalid token)
  const msg =
    TOAST_MESSAGES[`SIGNUP_FAILURE_${status}`] || TOAST_MESSAGES.SIGNUP_FAILURE
  dispatch(
    addErrorToast({
      message: msg,
    })
  )
}

// Session Refresh
export const requestRefreshSession = refreshToken => async dispatch => {
  dispatch(refreshSessionInProgress())
  try {
    const response = await refreshSession(refreshToken)
    dispatch(refreshSessionSuccess(getSinglePayloadFromResponse(response)))
    return true
  } catch (errorResponse) {
    dispatch(refreshSessionError(getPayloadFromErrorResponse(errorResponse)))
    const status = idx(errorResponse, e => e.response.status)
    if (
      errorResponse &&
      errorResponse.response &&
      (status === 401 || status === 403)
    ) {
      dispatch(sessionExpired())
    }
    return false
  }
}

export const refreshSessionInProgress = payload =>
  createInProgressAction(ACTION_SESSION_REFRESH, payload)

export const refreshSessionError = error =>
  createErrorAction(ACTION_SESSION_REFRESH, error)

export const refreshSessionSuccess = payload => dispatch => {
  setRefreshTokenToStorage(payload.refreshToken)
  setTokenToStorage(payload.token)
  setImageTokenToStorage(payload.imageToken)
  setAuthTokenHeader(payload.token)
  dispatch(createSuccessAction(ACTION_SESSION_REFRESH, payload))
  dispatch(portsRequest())
}

export const sessionExpired = () => dispatch => {
  try {
    clearLocalStorage()
  } catch (e) {}
  dispatch({
    type: SESSION_EXPIRED,
  })
  if (isHostSsoDomain()) {
    dispatch(
      routerActions.navigateTo(
        LOGINSSO,
        {
          code: 'logout',
        },
        { replace: true }
      )
    )
  } else {
    dispatch(routerActions.navigateTo(LOGIN))
  }
}

// Logout (/logout)
export const requestLogout = () => async dispatch => {
  dispatch({
    type: AUTH_LOGOUT_REQUEST,
  })
  dispatch(logoutInProgress())
  let isSuccess
  try {
    const result = await logoutUser()
    clearLocalStorage()
    dispatch(logoutSuccess(result))
    isSuccess = true
  } catch (error) {
    clearLocalStorage()
    dispatch(logoutError(error))
    isSuccess = false
  } finally {
    try {
      if (isHostSsoDomain()) {
        dispatch(
          routerActions.navigateTo(
            LOGINSSO,
            {
              code: 'logout',
            },
            { replace: true }
          )
        )
      } else {
        dispatch(routerActions.navigateTo(LOGIN))
      }
    } catch (e) {}
  }
  return isSuccess
}

export const logoutError = error => createErrorAction(ACTION_LOGOUT, error)
export const logoutSuccess = () => createSuccessAction(ACTION_LOGOUT)

export const logoutInProgress = () => createInProgressAction(ACTION_LOGOUT)

// Request new password
export const requestNewPassword = email => async dispatch => {
  const payload = { email }
  dispatch(createRequestAction(ACTION_PASSWORD_REQUEST_NEW, payload))
  dispatch(requestNewPasswordInProgress(payload))
  try {
    const response = await requestNewPasswordApi(payload)
    const result = getSinglePayloadFromResponse(response)
    dispatch(requestNewPasswordSuccess(result))
    return true
  } catch (errorResponse) {
    if (errorResponse instanceof Error) {
      throw errorResponse
    }
    const error = getPayloadFromErrorResponse(errorResponse)
    dispatch(requestNewPasswordError(error))
    return false
  }
}

export const requestNewPasswordSuccess = result => dispatch => {
  dispatch(createSuccessAction(ACTION_PASSWORD_REQUEST_NEW, result))
  dispatch(
    addSuccessToast({
      message: TOAST_MESSAGES.REQUEST_NEW_PASSWORD_SUCCESS,
    })
  )
}

export const requestNewPasswordInProgress = payload =>
  createInProgressAction(ACTION_PASSWORD_REQUEST_NEW, payload)

export const requestNewPasswordError = error =>
  createErrorAction(ACTION_PASSWORD_REQUEST_NEW, error)

// Request a password reset
export const requestResetPassword = (token, password) => async dispatch => {
  const payload = { token, passwordHash: hash(password) }
  dispatch(createRequestAction(ACTION_PASSWORD_RESET, payload))
  dispatch(resetPasswordInProgress(payload))
  try {
    const response = await resetPassword(payload)
    const result = getSinglePayloadFromResponse(response)
    dispatch(resetPasswordSuccess(result))
    return true
  } catch (errorResponse) {
    dispatch(resetPasswordError(errorResponse))
    return false
  }
}

export const resetPasswordSuccess = result => dispatch => {
  createSuccessAction(ACTION_PASSWORD_RESET, result)

  dispatch(
    addSuccessToast({
      message: TOAST_MESSAGES.REQUEST_PASSWORD_RESET_SUCCESS,
    })
  )
}

export const resetPasswordInProgress = payload =>
  createInProgressAction(ACTION_PASSWORD_RESET, payload)

export const resetPasswordError = errorResponse => dispatch => {
  const error = getPayloadFromErrorResponse(errorResponse)
  const status = idx(errorResponse, e => e.response.status)
  const msg =
    TOAST_MESSAGES[`RESET_PASSWORD_ERROR_${status}`] ||
    TOAST_MESSAGES.RESET_PASSWORD_ERROR
  dispatch(
    addErrorToast({
      message: msg,
    })
  )
  createErrorAction(ACTION_PASSWORD_RESET, error)
}

// update password (logged in user)
export const requestUpdatePassword = payload => async dispatch => {
  dispatch(createRequestAction(ACTION_PASSWORD_UPDATE, payload))
  dispatch(updatePasswordInProgress(payload))
  try {
    const response = await updatePassword(payload)
    const result = getSinglePayloadFromResponse(response)
    dispatch(updatePasswordSuccess(result))
  } catch (errorResponse) {
    const error = getPayloadFromErrorResponse(errorResponse)
    dispatch(updatePasswordError(error))
  }
}

export const updatePasswordSuccess = result =>
  createSuccessAction(ACTION_PASSWORD_UPDATE, result)

export const updatePasswordInProgress = payload =>
  createInProgressAction(ACTION_PASSWORD_UPDATE, payload)

export const updatePasswordError = error =>
  createErrorAction(ACTION_PASSWORD_UPDATE, error)

export const AUTH_SET_SESSION_STATUS = 'AUTH_SET_SESSION_STATUS'
export const setSessionStatus = status => ({
  type: AUTH_SET_SESSION_STATUS,
  payload: status,
})
