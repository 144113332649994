export const LOGIN = 'login'
export const LOGINSSO = 'sso'
export const LOGOUT = 'logout'
export const SIGN_UP = 'sign_up'
export const RESET_PASSWORD = 'reset_password'
export const RESET_PASSWORD_TOKEN = 'reset_password_token'
export const PILOTAGES = 'pilotages'
export const NEW_PILOTAGE = 'create'
export const DRAFT_PILOTAGE = 'edit_draft'
export const REVIEW_PILOTAGE = 'edit_review'
export const PILOTAGE_DETAIL = 'pilotage'
export const PILOTAGE_VESSEL = 'pilotage.vessel'
export const EDIT_VESSEL = 'pilotage.edit_vessel'
export const EDIT_PILOTAGE = 'pilotage.edit'
export const PILOTAGE_RISKS = 'pilotage.risks'
export const PILOTAGE_RISKS2 = 'pilotage.risks2'
export const PILOTAGE_TUGS = 'pilotage.tugs'
export const PILOTAGE_PLAN = 'pilotage.plan'
export const PILOTAGE_SUMMARY = 'pilotage.summary'
export const PILOTAGE_ACCEPTANCE = 'pilotage.acceptance'
export const PILOTAGE_ADMIN = 'pilotage.admin'
export const PILOTAGE_PLAN_STAGE = 'pilotage_plan_stage'
export const PILOTAGE_PLAN_BERTHING_ARRIVAL = 'pilotage_plan_berthing_arrival'
export const PILOTAGE_PLAN_BERTHING_DEPARTURE =
  'pilotage_plan_berthing_departure'
export const DEBUG = 'debug'

const routes = [
  { name: LOGIN, path: '/login' },
  { name: LOGINSSO, path: '/sso?:code' },
  { name: LOGOUT, path: '/logout' },
  { name: SIGN_UP, path: '/sign-up/:token' },
  { name: RESET_PASSWORD, path: '/reset-password' },
  { name: RESET_PASSWORD_TOKEN, path: '/reset-password/:token' },
  {
    name: PILOTAGES,
    path:
      '/?:page&:pageSize&:search&:status&:pilot&:movement&:vessel&:startDate&:endDate',
  },
  { name: NEW_PILOTAGE, path: '/pilotage/new' },
  { name: DRAFT_PILOTAGE, path: '/pilotage/draft/:id' },
  { name: REVIEW_PILOTAGE, path: '/pilotage/review/:id' },

  { name: PILOTAGE_DETAIL, path: '/pilotage/:id', forwardTo: PILOTAGE_VESSEL },
  { name: PILOTAGE_VESSEL, path: '/vessel' },
  { name: EDIT_VESSEL, path: '/vessel/edit' },
  { name: EDIT_PILOTAGE, path: '/edit' },
  { name: PILOTAGE_RISKS, path: '/risks' },
  { name: PILOTAGE_RISKS2, path: '/risks2' },
  { name: PILOTAGE_TUGS, path: '/tugs' },
  { name: PILOTAGE_PLAN, path: '/plan' },
  { name: PILOTAGE_SUMMARY, path: '/summary' },
  { name: PILOTAGE_ACCEPTANCE, path: '/acceptance' },
  { name: PILOTAGE_ADMIN, path: '/admin' },
  { name: PILOTAGE_PLAN_STAGE, path: '/pilotage/:id/stage/:stageId' },
  {
    name: PILOTAGE_PLAN_BERTHING_ARRIVAL,
    path: '/pilotage/:id/berthing/arrival',
  },
  {
    name: PILOTAGE_PLAN_BERTHING_DEPARTURE,
    path: '/pilotage/:id/berthing/departure',
  },
  {
    name: DEBUG,
    path: '/debug'
  }
]

export const DEFAULT_ROUTE = PILOTAGES

// This is a workaround to stop modal routes from displaying
// an animation when mounting
export const routeMetadata = {
  [NEW_PILOTAGE]: {
    group: PILOTAGES,
  },
  [EDIT_VESSEL]: {
    group: PILOTAGE_VESSEL,
  },
  [EDIT_PILOTAGE]: {
    group: PILOTAGE_VESSEL,
  },
}

const anonymousRoutes = [
  LOGIN,
  LOGINSSO,
  LOGOUT,
  SIGN_UP,
  RESET_PASSWORD,
  RESET_PASSWORD_TOKEN,
]

export const isAuthenticatedRoute = routeName =>
  anonymousRoutes.indexOf(routeName) === -1

export const isAnonymousRoute = routeName =>
  anonymousRoutes.indexOf(routeName) !== -1

export default routes
